import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'

import { Layout } from '../components'
import { Intro } from '../components'
import { Fade } from '../utils'

import { container, padding, bgImage, bgIcon, filterItem } from '../styles/global'
import { media } from '../styles/utils'
import {
    heading1,
    heading2,
    subheading1,
    subheading2,
    caption,
} from '../styles/type'
import { brown3, brown1, brown4, brown2 } from '../styles/colors'
import downArrow from '../assets/images/icons/down-arrow.svg'

const RecentTransactions = props => {
    const graphData = useStaticQuery(query).allWordpressInfopages.nodes[0]
    const data = JSON.parse(graphData.acf_json)
    const [activeService, setActiveService] = useState(false)
    const [activeSector, setActiveSector] = useState(false)    
    
    const handleFilterChange = (type, slug) => {
        if (type == 'service') {
            if (activeService == slug) {
                return setActiveService(false)
            }
            
            setActiveService(slug)
            setActiveSector(false)
        }

        if (type == 'sector') {
            if (activeSector == slug) {
                return setActiveSector(false)
            }
            
            setActiveSector(slug)
            setActiveService(false) 
        }
    }
    
    const renderFilters = () => {
        const services = data.services.map((item, i) => {
            return (
                <FilterItem 
                    key={i}
                    active={activeService == item.slug}
                    onClick={() => handleFilterChange('service', item.slug)}
                    dangerouslySetInnerHTML={{__html: item.title}}
                />
            )
        })

        const sectors = data.sectors.map((item, i) => {
            return (
                <FilterItem 
                    key={i}
                    active={activeSector == item.slug}
                    onClick={() => handleFilterChange('sector', item.slug)}
                    dangerouslySetInnerHTML={{__html: item.title}}
                />
            )
        })

        return (
            <Filters>
                <Heading>Sort</Heading>
                <FiltersContainer>
                    <Filter>
                        <Subheading>
                            Sector
                            <Icon src={downArrow} />
                        </Subheading>
                        <FilterItem 
                            key={'all'}
                            active={false}
                            onClick={() => handleFilterChange('sector', false)}
                            dangerouslySetInnerHTML={{__html: 'All'}}
                        />
                        {sectors}
                    </Filter>

                    <Filter>
                        <Subheading>
                            Service
                            <Icon src={downArrow} />
                        </Subheading>
                        <FilterItem 
                            key={'all'}
                            active={false}
                            onClick={() => handleFilterChange('service', false)}
                            dangerouslySetInnerHTML={{__html: 'All'}}
                        />
                        {services}
                    </Filter>
                </FiltersContainer>
            </Filters>
        )
    }

    const renderTransactions = () => {
        return data.transactions.map((item, i) => {
            const logo = item.acf.logo
            const date = item.acf.date
            const formatted_title = item.acf.formatted_title
            const heading = item.acf.heading
            const short_description = item.acf.short_description

            // for filtering
            
            const sector = item.acf.sectors && item.acf.sectors[0];
            const service = item.acf.services && item.acf.services[0] && item.acf.services[0].post_name

            if (sector && activeSector && activeSector !== sector.slug) return
            if (service && activeService && activeService !== service) return

            return (
                <TransactionItem key={i} whileHover={'hover'}>
                    <ImageWrapper>
                        <Image
                            key={logo.ID}
                            src={logo.sizes ? logo.sizes.medium2 : null}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        key={logo.ID}
                                        {...animatedImage}
                                    />
                                )
                            }}
                        </Image>
                    </ImageWrapper>

                    <TransactionDescription>
                        <TransactionInfo key={logo.ID} {...animatedPosition}>
                            <Meta>
                                <Date>{date}</Date>
                                {sector && (
                                    <Sector
                                        dangerouslySetInnerHTML={{
                                            __html: sector.name,
                                        }}
                                    />
                                )}
                            </Meta>

                            <Title
                                dangerouslySetInnerHTML={{
                                    __html: formatted_title,
                                }}
                            />

                            <Heading>{heading}</Heading>

                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: short_description,
                                }}
                            />
                        </TransactionInfo>
                    </TransactionDescription>
                </TransactionItem>
            )
        })
    }

    return (
        <Layout
            meta={data && data.seo}
            desktopHeaderTheme={'dark'}
        >
            <Intro
                heading={data.intro_heading}
                subheading={data.intro_subheading}
                description={data.intro_text}
            />

            <Transactions>
                <Fade delay={1200}>
                    <Container>
                        <Grid>
                            {renderFilters()}
                            {renderTransactions()}
                        </Grid>
                    </Container>
                </Fade>
            </Transactions>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

// Layout

const Container = styled.div`
    ${container}
    ${padding}
`
const Grid = styled.div``

// Animations

const animatedImage = {
    initial: {
        scale: 1,
    },
    variants: {
        hover: {
            scale: 0.95,
        },
    },
    transition: { duration: 1 },
}

const animatedPosition = {
    variants: {
        hover: {
            y: '-12px',
        },
    },
    transition: { duration: 0.3 },
}

// Transactions Grid Layout

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled(motion.div)`
    background-image: url(${props => props.image});
    ${bgIcon};
    height: 100%;
`

const Transactions = styled.div`
    width: 100%;
    padding-bottom: 180px;
    background-color: ${brown3};

    ${media.tabletLandscape`
        padding-bottom: 120px;
    `}

    ${media.phone`
        padding-bottom: 64px;
    `}

    ${Grid} {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        margin: 0 -10px -60px;

        ${media.phone`
            display: block;
            margin: 0;
        `}
    }
`

// Transaction Filters

const Icon = styled.img``
const FiltersContainer = styled.div``
const Filter = styled.div``
const Filters = styled.div`
    width: 50%;
    padding: 0 10px;
    box-sizing: border-box;

    ${media.tablet`
        width: 66.66%;
    `}

    ${media.phone`
        width: 100%;
        height: auto;
        padding: 0;
        padding-top: 32px;
        padding-bottom: 64px;
    `}

    ${FiltersContainer} {
        display: flex;
        justify-content: space-between;
        width: 400px;
        max-width: 100%;

        ${media.phone`
            display: block;
            width: 100%;
        `}
    }

    ${Filter} {
        width: 190px;
        max-width: 100%;

        ${media.phone`
            width: 100%;

            &:first-child {
                margin-bottom: 32px;
            }
        `}
    }

    ${Heading} {
        padding-bottom: 8px;
        ${subheading2};
        text-transform: uppercase;
    }

    ${Subheading} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 12px;
        border-top: 2px solid ${brown1};
        ${subheading2};
        margin-bottom: 4px;
    }
`

const FilterItem = styled.div`
    ${filterItem}
`

// Transaction Grid Items

const ImageWrapper = styled.div``
const TransactionDescription = styled.div``
const TransactionInfo = styled(motion.div)``
const Meta = styled.div``
const Date = styled.div``
const Sector = styled.div``
const Title = styled.div``
const TransactionItem = styled(motion.div)`
    display: inline-block;
    width: 25%;
    margin-bottom: 60px;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;

    ${media.tablet`
        width: 33.33%;
        margin-bottom: 32px;
    `}

    ${media.phone`
        width: 100%;
        margin-bottom: 20px;
        padding: 0;
    `}

    ${ImageWrapper} {
        overflow: hidden;
        height: 180px;
        background-color: white;
        padding: 40px;
        box-sizing: border-box;

        ${media.tabletLandscape`
            padding: 20px;
        `}

        ${media.phone`
            height: 160px;
        `}
    }

    ${TransactionDescription} {
        height: 100%;
        background-color: ${brown4};
    }

    ${TransactionInfo} {
        padding: 20px;
        box-sizing: border-box;
        background-color: ${brown4};
        color: ${brown1};
    }

    ${Meta} {
        width: 100%;
        padding-bottom: 16px;
        border-bottom: 1px solid ${brown1};

        ${Date} {
            ${subheading2}
        }

        ${Sector} {
            margin-top: 6px;
            ${subheading1};
        }
    }

    ${Title} {
        margin-top: 10px;
        ${heading2};

        ${media.phone`
            font-size: 24px;
        `}

        p {
            margin: 0;
        }

        em {
            font-weight: 300;
            font-style: italic;
        }

        strong {
            font-weight: 600;
        }
    }

    ${Heading} {
        margin-top: 10px;
        ${subheading2};
    }

    ${Description} {
        margin-top: 4px;
        ${caption};
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "recent-transactions" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default RecentTransactions
